import React from "react";
import { Link } from 'react-router-dom';
import BreadCrumb from "../componentes/BreadCrumbs/BCRepositorioFichasLaVictoria"
let datos = require('../data/datos_contenidos.json');


function Contenidos() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return(
		<div class="container-fluid py-3 lh-sm estilo_proyecto">
			<div class="bd-highlight text-center"><h1 class="fw-bold">Contenidos</h1></div>
			<div class="border rounded container-fluid py-2">
				<div class="row g-3 d-flex justify-content-center">
					{datos.Contenidos.map((itemcontenido) => (
						<div class="col-lg-2 col-md-4 col-sm-12">
							<div class="card">
								<img class="card-img-top" src={require("../"+itemcontenido.rutaimagencont)} alt={itemcontenido.altimagencont}/>
								<div class="card-body">
									<Link class="text-decoration-none" to={"/"+itemcontenido.rutapaginacont}><div class="d-grid gap-2"><button type="button" class="btn btn-outline-primary btn-block"><small>{itemcontenido.textobotoncont}</small></button></div></Link>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<BreadCrumb />
		</div>
	)
}

export default Contenidos;