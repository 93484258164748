import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function Error() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return(
		<div className="jumbotron text-center" style={{ background: 'none', color: 'white'}}>
			<h2>Comunidad AM</h2>
			<p className="lead" style={{ color: 'cornflowerblue'}}>( {window.location.href} )</p>
			<p className="lead" style={{ color: 'gray'}}>No cuentas con los permisos suficientes para acceder a este recurso</p>
			<hr class="my-4"></hr>
			<p><Link to="/" style={{ textDecoration: 'none'}}><Button variant="primary">Entendido</Button></Link></p>
		</div>
	)
}
  
export default Error;