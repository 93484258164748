import React from "react";
import BCRepositorio from '../componentes/BreadCrumbs/BCRepositorio';
import { Link } from 'react-router-dom';
let datos = require('../data/datos_repositorio.json');

function RepositorioFichas() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(    
    <div class="container-fluid py-3 lh-sm estilo_layout_contenido">
      <div class="text-center"><h1 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">REPOSITORIO</h1></div>
      <div class="bd-highlight text-center">
        <h3 class="estilo_titulos_subtitulos_base estilo_verde_claro">{datos.textoTituloDosFichas}</h3>
      </div>
      <div class="border rounded container-fluid pt-3 estilo_fondo_negro_semitransparente">
        {datos.DescripcionFichasRepositorio.map((parrafo_descripcion) => (
          <p><small>{parrafo_descripcion}</small></p>
        ))}
        {datos.menuRepositorioFichas.map((menu_repositorio) => (
          <div class="row justify-content-center pb-3 px-3">
            <div class="p-2 border rounded col-lg-4 col-md-4 col-sm-12 estilo_fondo_opciones">
              <Link to={"/repositorio/"+menu_repositorio.rutalink} class="text-decoration-none link-dark">
                <div class="d-flex align-items-center">
                  <div><img src={require("../"+menu_repositorio.rutaimagen)} style={{'height':'30px'}} alt={menu_repositorio.altimagen}/></div>
                  <div class="ms-2 text-start"><strong>{menu_repositorio.textolink}</strong></div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <BCRepositorio />
    </div>
  )
}

export default RepositorioFichas;