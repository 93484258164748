import React from "react";
import BCRepositorio from '../componentes/BreadCrumbs/BCRepositorio';
let datos = require('../data/datos_repositorio.json');

function RepositorioDocumentos() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(    
    <div class="container-fluid py-3 lh-sm estilo_layout_contenido">
        <div class="text-center"><h1 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">REPOSITORIO</h1></div>
        <div class="bd-highlight text-center">
          <h3 class="estilo_titulos_subtitulos_base estilo_verde_claro">{datos.textoTituloDosDocumentos}</h3>
        </div>
        <div class="border rounded container-fluid pt-3 estilo_fondo_negro_semitransparente">
          {datos.menuRepositorioDocumentos.map((menu_repositorio_doc) => (
            <div class="row justify-content-center pb-3 px-3">
              <div class="p-2 border rounded col-lg-4 col-md-4 col-sm-12 estilo_fondo_opciones">
                <a href={"../../"+menu_repositorio_doc.linkDocumento} target="_blank" rel="noopener noreferrer" class="link-dark text-decoration-none ">
                  <div class="d-flex align-items-center">
                    <div>
                      <img src={require("../"+menu_repositorio_doc.rutaImagen)} style={{'height':'30px'}} alt={menu_repositorio_doc.altImagen}/>
                    </div>
                    <div class="ms-2 text-start"><strong>{menu_repositorio_doc.textoLink}</strong></div>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
        <BCRepositorio />
    </div>
  )
}

export default RepositorioDocumentos;