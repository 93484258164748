import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const VisorLaminas = () => {
  React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

  //SELECCION ORIGEN PETICION
  const { idorigenpeticion } = useParams();
  let urlarchivo = '';
  let tituloarchivo = '';
  let urlpaginaorigen = '';

  switch (idorigenpeticion) {
    case 'sm_plazaunion':
      tituloarchivo = 'Plaza La Unión';
      urlarchivo = 'archivos/museo_san_miguel/01_plaza_union/lamina_01_SM-01_Plaza_La_Union.png';
      urlpaginaorigen = 'museo-san-miguel/sm-plaza-union';
      break;
    case 'sm_losprisioneros':
      tituloarchivo = 'Los Prisioneros';
      urlarchivo = 'archivos/museo_san_miguel/02_los_prisioneros/lamina_02_SM-02_Los_Prisioneros.png';
      urlpaginaorigen = 'museo-san-miguel/sm-los-prisioneros';
      break;
    case 'sm_caminoiluminacion':
      tituloarchivo = 'Camino hacia la iluminación';
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      urlpaginaorigen = 'museo-san-miguel/sm-camino-iluminacion';
      break;
    case 'sm_hermanos':
      tituloarchivo = 'Hermanos';
      urlarchivo = 'archivos/museo_san_miguel/04_hermanos/lamina_04_SM-04_Hermanos.png';
      urlpaginaorigen = 'museo-san-miguel/sm-hermanos';
      break;
    case 'lv_31anos':
      tituloarchivo = 'Sin título (31 años de La Victoria)';
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      urlpaginaorigen = 'poblacion-la-victoria/lv-31-anos-victoria';
      break;
    case 'lv_dedicjarlandubois':
      tituloarchivo = 'Sin título (dedicado a André Jarlan y Pierre Dubois)';
      urlarchivo = 'archivos/poblacion_la_victoria/02_dedicatoria_jarlan_dubois/lamina_06_LV-02_Sin_titulo_(dedicado_a_Andre_Jarlan_y_Pierre_Dubois).png';
      urlpaginaorigen = 'poblacion-la-victoria/lv-dedicatoria-jarlan-dubois';
      break;
    case 'lv_casajarlandubois':
      tituloarchivo = 'Sin título (mural Casa Parroquial de André Jarlan y Pierre Dubois)';
      urlarchivo = 'archivos/poblacion_la_victoria/03_parroquial_jarlan_dubois/lamina_07_LV-03_Sin_titulo_(mural_Casa_Parroquial_de_Andre_Jarlan_y_Pierre_Dubois).png';
      urlpaginaorigen = 'poblacion-la-victoria/lv-parroquial-jarlan-dubois';
      break;
    case 'lp_catrillanca':
      tituloarchivo = 'Camilo Catrillanca';
      urlarchivo = 'archivos/poblacion_la_pincoya/01_camilo_catrillanca/lamina_08_LP-01_Camilo_Catrillanca.png';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-camilo-catrillanca';
      break;
    case 'lp_mujeryallende':
      tituloarchivo = 'La mujer pobladora y Allende';
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-mujer-pobladora-allende';
      break;
    case 'lp_nicomentarios':
      tituloarchivo = 'Ni comentarios ni opiniones, mi cuerpo mis decisiones';
      urlarchivo = 'archivos/poblacion_la_pincoya/03_ni_comentarios_ni_opiniones/lamina_10_LP-03_Ni_comentarios_ni_opiniones_mi_cuerpo_mis_decisiones.png';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-ni-comentarios-ni-opiniones';
      break;
    case 'lp_lapincoya':
      tituloarchivo = 'La Pincoya';
      urlarchivo = 'archivos/poblacion_la_pincoya/04_poblacion_la_pincoya/lamina_11_LP-04_Poblacion_La_Pincoya.png';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-poblacion-la-pincoya';
      break;
    case 'lp_orgullosecundario':
      tituloarchivo = 'Orgullo Secundario';
      urlarchivo = 'archivos/poblacion_la_pincoya/05_orgullo_secundario/lamina_12_LP-05_Orgullo_secundario.png';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-orgullo-secundario';
      break;
    case 'sr_estacionneozona':
      tituloarchivo = 'Estación Neozona';
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      urlpaginaorigen = 'avenida-santa-rosa/sr-estacion-neozona';
      break;
    case 'sr_violetabestia':
      tituloarchivo = 'Violeta contra la bestia';
      urlarchivo = 'archivos/avenida_santa_rosa/02_violeta_contra_bestia/lamina_SR-02_Violeta_contra_la_bestia.png';
      urlpaginaorigen = 'avenida-santa-rosa/sr-violeta-contra-bestia';
      break;
    case 'rm_latemplanza':
      tituloarchivo = 'La Templanza';
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      urlpaginaorigen = 'rio-mapocho/rm-la-templanza';
      break;
    case 'rm_composicion':
      tituloarchivo = 'Sin título (composición de 4 obras)';
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      urlpaginaorigen = 'rio-mapocho/rm-composicion-4-obras';
      break;
    case 'rm_mapochohistoria':
      tituloarchivo = 'El Mapocho vuelve a pintarse de historia';
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      urlpaginaorigen = 'rio-mapocho/rm-mapocho-historia';
      break;
    default: 
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      break;
    }
  // FIN SELECCION ORIGEN PETICION
  
  useEffect(() => {

    /* DESCARGAR LAMINA */
    let btnDescargar = document.querySelector('#botonDescargar');
    let imgLamina = document.querySelector('#imagenLamina');
    
    btnDescargar.addEventListener('click', () => {
      let imgPath = imgLamina.getAttribute('src');
      let fileName = getFileName(imgPath);
      saveAs(imgPath, fileName);
    });
    
    function getFileName(str) {
      return str.substring(str.lastIndexOf('/')+1);
    }

    /* ZOOM LAMINA */
    let btnZoomIn = document.querySelector("#botonAmpliar");
    let btnZoomOut = document.querySelector("#botonReducir");
    let img = document.querySelector("#imagenLamina");
    let scale = 1;

    btnZoomIn.addEventListener("click", (e) => {
      scale += 0.25;
      img.style.transform = `scale(${scale})`;
    });

    btnZoomOut.addEventListener("click", (e) => {
      if (scale >= 1.25) {
        scale -= 0.25;
        img.style.transform = `scale(${scale})`;
      }
    });
  }, []);
  

  return (
    <div class="container-fluid estilo_layout_contenido">
    <div class="estilo_div_principal_laminas mb-3">
      <div class="bd-highlight text-center mt-2">
        <h4 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">LÁMINA</h4>
        <h5 class="estilo_titulos_subtitulos_base estilo_verde_claro">{tituloarchivo}</h5>
      </div>
      
      {/* SEECION BARRA CONTROLES */}
      <div class="estilo_barra_controles_visor_laminas">
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip_ampliar">Ampliar</Tooltip>}>
          <button id="botonAmpliar" type="button" class="btn btn-outline-secondary">
            <h5 class="estilo_icono_boton_visor"><i class="bi bi-zoom-in"></i></h5>
          </button>
        </OverlayTrigger>
        <p class="fs-4 align-top estilo_separador_boton_visor">|</p>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip_reducir">Reducir</Tooltip>}>
          <button id="botonReducir" type="button" class="btn btn-outline-secondary">
            <h5 class="estilo_icono_boton_visor"><i class="bi bi-zoom-out"></i></h5>
          </button>
        </OverlayTrigger>
        <p class="fs-4 align-top estilo_separador_boton_visor">|</p>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip_restablecer">Restablecer</Tooltip>}>
        <Link to="" onClick={() => window.location.reload(false)} style={{paddingBottom: '14px'}}>
          <button id="botonRestablecer" type="button" class="btn btn-outline-secondary">
            <h5 class="estilo_icono_boton_visor"><i class="bi bi-arrow-repeat"></i></h5>
          </button>
        </Link>
        </OverlayTrigger>
        <p class="fs-4 align-top estilo_separador_boton_visor">|</p>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip_descargar">Descargar</Tooltip>}>
          <button id="botonDescargar" type="button" class="btn btn-outline-secondary">
            <h5 class="estilo_icono_boton_visor"><i class="bi bi-download"></i></h5>
          </button>
        </OverlayTrigger>
      </div>

      {/* SEECION VISOR LAMINA */}
      <div class="estilo-contenedor-visor-laminas">
       <img id="imagenLamina" class="estilo_visor_laminas" src={urlarchivo} alt="Imagen Lámina"/>
     </div>
    </div>

    {/* SEECION FOOTER */}
    <div class="container-fluid fixed-bottom estilo_contenedor_breadcrumb_footer">
      <nav aria-label="breadcrumb" class="estilo_breadcrumb_footer">
        <ol class="breadcrumb pt-1 px-2 justify-content-center">
          <i class="bi bi-three-dots-vertical"></i>
          <li class="breadcrumb-item"><Link to={"/repositorio/"+urlpaginaorigen}>Volver</Link></li>
          <i class="bi bi-three-dots-vertical"></i>
        </ol>
      </nav>
    </div>
     
</div>
  );
};

export default VisorLaminas;