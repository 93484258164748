import React from "react";
import { Link } from 'react-router-dom';
import BCRepositorioFichasLaPincoya from '../../../componentes/BreadCrumbs/BCRepositorioFichasLaPincoya';
let datos = require('../../../data/repositorio/fichas/poblacion_la_pincoya/datos_ficha_poblacion_la_pincoya.json');

function LPPoblacionLaPincoya() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
  return(
    <div class="container-fluid py-3 lh-sm estilo_layout_contenido">
			<div class="text-center"><h1 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">{datos.tituloFichaPrincipal}</h1></div>
      <div class="bd-highlight text-center">
        <h3 class="estilo_titulos_subtitulos_base estilo_verde_claro">{datos.tituloFichaSecundario}</h3>
      </div>
	    <div class="row g-3">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="card border rounded border-white bg-transparent h-100">
            <div class="d-flex card-header estilo_cabeceras_equipo">
							<div class="col-12 text-start estilo_texto_nombre"><h6 class="fw-bold">Descripción</h6></div>
            </div>
            <div class="card-body rounded-bottom estilo_fondo_negro_semitransparente">
							<small>
								<table class="table text-white">
									<tbody>
										<tr><td><strong>AUTORES:</strong><br/>{datos.fichaAutores}</td></tr>
										<tr><td><strong>TÍTULO:</strong><br/>{datos.fichaTitulo}</td></tr>
										<tr><td><strong>FECHA DE CREACIÓN:</strong><br/>{datos.fichaFechaCreacion}</td></tr>
										<tr><td><strong>TÉCNICA / MATERIAL:</strong><br/>{datos.fichaTecnicaMaterial}</td></tr>
										<tr><td><strong>ENCARGADO/S DE LA GESTIÓN DE LA OBRA:</strong><br/>{datos.fichaEncargadosObra}</td></tr>
										<tr><td><strong>DIMENSIONES (cm):</strong><br/><u>Alto</u>: {datos.fichaAlto}<br/><u>Ancho</u>: {datos.fichaAncho}<br/><u>Profundidad</u>: {datos.fichaProfundidad}</td></tr>
										<tr><td><strong>UBICACIÓN:</strong><br/><u>Comuna</u>: {datos.fichaComuna}<br/><u>Región</u>: {datos.fichaRegion}<br/><u>País</u>: {datos.fichaPais}</td></tr>
										<tr><td><strong>DIRECCIÓN:</strong><br/>{datos.fichaDireccion}</td></tr>
										<tr>
											<td>
												{/* <a href={"../../"+datos.fichaUrlFicha} target="_blank" rel="noopener noreferrer" class="text-decoration-none"><button type="button" class="btn btn-secondary btn-sm border rounded me-2"><i class="bi bi-filetype-pdf"></i> Ficha</button></a>
												<a href={"../../"+datos.fichaUrlLamina} target="_blank" rel="noopener noreferrer" class="text-decoration-none"><button type="button" class="btn btn-secondary btn-sm border rounded me-2"><i class="bi bi-filetype-png"></i> Lámina</button></a> */}
												<Link to={"/visorfichas/"+datos.idorigenpeticion} class="text-decoration-none"><button type="button" class="btn btn-secondary btn-sm border rounded me-2"><i class="bi bi-filetype-pdf"></i> Ficha</button></Link>
												<Link to={"/visorlaminas/"+datos.idorigenpeticion} class="text-decoration-none"><button type="button" class="btn btn-secondary btn-sm border rounded me-2"><i class="bi bi-filetype-png"></i> Lámina</button></Link>
											</td>
										</tr>
									</tbody>
								</table>
							</small>
            </div>
          </div>
        </div>
				<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<div class="card border rounded border-white bg-transparent h-100">
						<div class="d-flex card-header estilo_cabeceras_equipo">
							<div class="col-12 text-start estilo_texto_nombre"><h6 class="fw-bold">Fotografías</h6></div>
						</div>
						<div class="card-body rounded-bottom estilo_fondo_negro_semitransparente">
							<div id="carouselExampleControls" class="carousel slide border" data-bs-ride="carousel" >
								<div class="carousel-indicators">
									<button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
									<button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1" class="" aria-label="Slide 2"></button>
									<button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="2" class="" aria-label="Slide 3"></button>
									<button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="3" class="" aria-label="Slide 4"></button>
									<button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="4" class="" aria-label="Slide 5"></button>
								</div>
								<div class="carousel-inner bg-secondary">
									{datos.carruselImagenesFichas.map((item_imagen_ficha) => (
										<div class={item_imagen_ficha.classImagen}><img src={require("../../../imagenes/repositorio/"+item_imagen_ficha.rutaImagen)} class="d-block estilo_img_carrusel_fichas" alt={item_imagen_ficha.altImagen} data-bs-toggle="modal" data-bs-target={"#"+item_imagen_ficha.nombreModal} /></div>
									))}
								</div>
								<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
									<span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="visually-hidden">Anterior</span>
								</button>
								<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
									<span class="carousel-control-next-icon" aria-hidden="true"></span><span class="visually-hidden">Siguiente</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 mt-3">
				<div class="card border rounded border-white bg-transparent">
					<div class="card-header estilo_cabeceras_equipo">
						<div class="row">
							<div class="col-7 text-start estilo_texto_nombre"><h6 class="fw-bold">Mapa de Ubicación</h6></div>
							<div class="col-5 text-end estilo_texto_nombre"><Link to="" onClick={() => window.location.reload(false)} class="text-decoration-none estilo_link_restaurar_mapa"><small><i class="bi bi-arrow-repeat"></i> Restaurar Mapa</small></Link></div>
						</div>
					</div>
					<div class="card-body rounded-bottom estilo_fondo_negro_semitransparente pb-2">
            <iframe title="uniqueTitle" width="100%" height="350px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=-70.63709020614625%2C-33.36719266421909%2C-70.62979459762575%2C-33.36196863724705&amp;layer=mapnik&amp;marker=-33.36458068993873%2C-70.63344240188599" style={{border: '1px solid white'}}></iframe>
					</div>
				</div>
			</div>
			{datos.carruselImagenesFichas.map((modal_imagen) => (
				<div class="modal fade" id={modal_imagen.nombreModal} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-lg">
						<div class="modal-content">
							<div class="modal-header">
								<h1 class="modal-title fs-6" id="exampleModalLabel"><small>{modal_imagen.altImagen}</small></h1>
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div class="modal-body"><img class="card-img" src={require("../../../imagenes/repositorio/"+modal_imagen.rutaImagen)} alt={modal_imagen.altImagen}/></div>
						</div>
					</div>
				</div>
			))}
		<BCRepositorioFichasLaPincoya />
    </div>
  )
}

export default LPPoblacionLaPincoya;