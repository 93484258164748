import React from "react";
import BCInicio from '../componentes/BreadCrumbs/BCInicio';
let datos = require('../data/datos_equipo.json');

function Equipo() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(
    <div class="container-fluid py-3 lh-sm estilo_layout_contenido">
      <div class="text-center"><h1 class="estilo_titulo_pagina_principal">EQUIPO</h1></div>
      {datos.Equipo.map((integrante) => ( 
        <React.Fragment>
          {/* CARD */}
          <div class="row">
            <div class="col-sm">
              <div class="card border border-white bg-transparent mb-3">
                <div class="d-flex card-header estilo_cabeceras_equipo">
                  <div class="col-7 text-start estilo_texto_nombre">
                    <h6 class="fw-bold">{integrante.nombre}</h6>
                  </div>
                  <div class="col-5 text-end estilo_boton_sintesis_curricular">
                    <button class="btn btn-sm btn-outline-success mb-1" data-bs-toggle="modal" data-bs-target={"#"+integrante.modal}>
                      {datos.textoBotonAbrirModalSintesis}
                    </button>
                  </div>
                </div>
                <div class="card-body rounded-bottom estilo_fondo_negro_semitransparente text-white">
                  <p class="card-text mb-0"><small>{integrante.descripcion}</small></p>
                </div>
              </div>
            </div>
          </div>

          {/* MODAL */}
          <div class="modal fade" id={integrante.modal} tabindex="-1" aria-labelledby="exampleModalScrollableTitle" style={{display: 'none'}} aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header pt-1 pb-0 estilo_cabecera_modal_equipo">
                  <div>
                    <h6 class="modal-title fw-bold" id="exampleModalScrollableTitle">{integrante.nombre}</h6>
                    <h6><small>{datos.textoSubtituloModalSintesis}</small></h6>
                  </div>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-0">
                  <ul class="list-group list-group-flush">
                    {integrante.sintesis.map((listasintesis) => 
                      <li class="list-group-item"><i class="bi bi-bookmark-fill pe-1"></i><small>{listasintesis.lineacurricular}</small></li>
                    )}
                  </ul>
                </div>
                <div class="modal-footer py-2 bg-light">
                  {integrante.web.map((sitioweb) => 
                   <div class="col-12 d-flex align-items-center">
                     <i class="bi bi-globe2 fs-5 ms-3 me-2 mb-2"></i><small><a href={sitioweb.urlweb} class="link-dark text-decoration-none" target="_blank" rel="noreferrer">{sitioweb.urlweb}</a></small>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}

      {/* SECCION EQUIPO DE APOYO */}
      <div class="row">
        <div class="col-sm">
          <div class="card border border-white bg-transparent mb-3">
            <div class="d-flex estilo_cabeceras_equipo">
              <div class="col-12 text-start estilo_texto_nombre"><h5 class="fw-bold">Equipo de apoyo</h5></div>
            </div>
            <div class="card-body rounded-bottom estilo_fondo_negro_semitransparente text-white">
              {datos.EquipoApoyo.map((apoyo) =>
                <div class="align-items-center text-start mb-3">
                  <i class="bi bi-person-check-fill fs-6 me-1"></i><small class="fw-bold">{apoyo.nombre}</small><small>{' : '}{apoyo.funcion}</small><br/>
                  <i class="bi bi-bookmark-fill fs-6 me-1"></i><small>{apoyo.profesion}</small>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BCInicio />
    </div>
  )
}

export default Equipo;