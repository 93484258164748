import { Link } from 'react-router-dom';

function BCRepositorioFichasLaPincoya() {
	return(
		<div class="container-fluid fixed-bottom estilo_contenedor_breadcrumb_footer">
			<nav aria-label="breadcrumb" class="estilo_breadcrumb_footer">
				<ol class="breadcrumb pt-1 px-2 justify-content-center">
					<i class="bi bi-three-dots-vertical"></i>
						<li class="breadcrumb-item"><Link to="/">Inicio</Link></li>
					<i class="bi bi-three-dots-vertical"></i>
						<li class="breadcrumb-item"><Link to="/repositorio">Repositorio</Link></li>
					<i class="bi bi-three-dots-vertical"></i>
						<li class="breadcrumb-item"><Link to="/repositorio-fichas">Fichas</Link></li>
					<i class="bi bi-three-dots-vertical"></i>
						<li class="breadcrumb-item"><Link to="/repositorio/poblacion-la-pincoya">Población la Pincoya</Link></li>
					<i class="bi bi-three-dots-vertical"></i>
				</ol>
			</nav>
		</div>
	)
}

export default BCRepositorioFichasLaPincoya;
