import React from "react";
import BCInicio from '../componentes/BreadCrumbs/BCInicio';
let datos = require('../data/datos_proyecto.json');
const imagenclass = 'col-lg-3 col-md-4 col-sm-12 p-2';

function Proyecto() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(
    <div class="container-fluid py-3 lh-sm estilo_proyecto">
      <div class="text-center"><h1 class="estilo_titulo_pagina_principal">PROYECTO</h1></div>
        <div class="border rounded container-fluid py-1 estilo_fondo_negro_semitransparente">
          <h3 class="estilo_items_pagina">Descripción</h3>
          {datos.Descripcion.map((parrafo_descripcion) => (<p><small>{parrafo_descripcion.parrafo}<i>{parrafo_descripcion.plus}</i></small></p>))}
          <br/>
          <h3 class="estilo_items_pagina">Agradecimientos</h3>
          {datos.Agradecimientos.map((parrafo_agradecimientos) => (<p><small>{parrafo_agradecimientos}</small></p>))}
          <p>{datos.Organizaciones.map((nombre_organizacion) => (<small>- {nombre_organizacion}<br/></small>))}</p>
          <div class="row">
            {datos.ImagenesProyecto.map((img_proyecto) => (
              <div class={imagenclass}><div class="card"><div class="card-body estilo-card-img-gallery"><img data-bs-toggle="modal" data-bs-target={"#"+img_proyecto.nombremodal} class="card-img" src={require("../"+img_proyecto.rutaimagen)} alt={img_proyecto.altimagen}/></div></div></div>
            ))}
          </div>
      </div>
      {datos.ImagenesProyecto.map((modal_proyecto) => (
        <div class="modal fade" id={modal_proyecto.nombremodal} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header estilo_cabecera_modal_fotos">
                <h1 class="modal-title fs-6 estilo_texto_cabecera_modal_fotos" id="exampleModalLabel"><small>{modal_proyecto.tituloimagen}</small></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body"><img class="card-img" src={require("../"+modal_proyecto.rutaimagen)} alt={modal_proyecto.altimagen}/></div>
            </div>
          </div>
        </div>
      ))}
      <BCInicio />
    </div>
  )
}

export default Proyecto;