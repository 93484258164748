import React, { useRef, useEffect } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { Link, useParams } from 'react-router-dom';
let flecha_izquierda = require('../imagenes/flecha_izquierda.png');
let flecha_derecha = require('../imagenes/flecha_derecha.png');


const VisorFichas = () => {
  React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
  
  //SELECCION ORIGEN PETICION
  const { idorigenpeticion } = useParams();
  let urlarchivo = '';
  let tituloarchivo = '';
  let urlpaginaorigen = '';

  switch (idorigenpeticion) {
    case 'sm_plazaunion':
      tituloarchivo = 'Plaza La Unión';
      urlarchivo = 'archivos/museo_san_miguel/01_plaza_union/ficha_01_SM-01_Nº0_Plaza_La_Union.pdf';
      urlpaginaorigen = 'museo-san-miguel/sm-plaza-union';
      break;
    case 'sm_losprisioneros':
      tituloarchivo = 'Los Prisioneros';
      urlarchivo = 'archivos/museo_san_miguel/02_los_prisioneros/ficha_02_SM-02_Nº1_Los_Prisioneros.pdf';
      urlpaginaorigen = 'museo-san-miguel/sm-los-prisioneros';
      break;
    case 'sm_caminoiluminacion':
      tituloarchivo = 'Camino hacia la iluminación';
      urlarchivo = 'archivos/museo_san_miguel/03_camino_iluminacion/ficha_03_SM-03_Nº30_Camino_hacia_la_iluminacion.pdf';
      urlpaginaorigen = 'museo-san-miguel/sm-camino-iluminacion';
      break;
    case 'sm_hermanos':
      tituloarchivo = 'Hermanos';
      urlarchivo = 'archivos/museo_san_miguel/04_hermanos/ficha_04_SM-04_Nº34_Hermanos.pdf';
      urlpaginaorigen = 'museo-san-miguel/sm-hermanos';
      break;
    case 'lv_31anos':
      tituloarchivo = 'Sin título (31 años de La Victoria)';
      urlarchivo = 'archivos/poblacion_la_victoria/01_31_anos_victoria/ficha_05_LV-01_Sin_titulo_(31_años_de_La_Victoria).pdf';
      urlpaginaorigen = 'poblacion-la-victoria/lv-31-anos-victoria';
      break;
    case 'lv_dedicjarlandubois':
      tituloarchivo = 'Sin título (dedicado a André Jarlan y Pierre Dubois)';
      urlarchivo = 'archivos/poblacion_la_victoria/02_dedicatoria_jarlan_dubois/ficha_06_LV-02_Sin_titulo_(dedicado_a_Andre_Jarlan_y_Pierre_Dubois).pdf';
      urlpaginaorigen = 'poblacion-la-victoria/lv-dedicatoria-jarlan-dubois';
      break;
    case 'lv_casajarlandubois':
      tituloarchivo = 'Sin título (mural Casa Parroquial de André Jarlan y Pierre Dubois)';
      urlarchivo = 'archivos/poblacion_la_victoria/03_parroquial_jarlan_dubois/ficha_07_LV-03_Sin_titulo_(mural_Casa_Parroquial_de_Andre_Jarlan_y_Pierre_Dubois).pdf';
      urlpaginaorigen = 'poblacion-la-victoria/lv-parroquial-jarlan-dubois';
      break;
    case 'lp_catrillanca':
      tituloarchivo = 'Camilo Catrillanca';
      urlarchivo = 'archivos/poblacion_la_pincoya/01_camilo_catrillanca/ficha_08_LP-01_Camilo_Catrillanca.pdf';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-camilo-catrillanca';
      break;
    case 'lp_mujeryallende':
      tituloarchivo = 'La mujer pobladora y Allende';
      urlarchivo = 'archivos/poblacion_la_pincoya/02_mujer_pobladora_allende/ficha_09_LP-02_La_mujer_pobladora_y_Allende.pdf';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-mujer-pobladora-allende';
      break;
    case 'lp_nicomentarios':
      tituloarchivo = 'Ni comentarios ni opiniones, mi cuerpo mis decisiones';
      urlarchivo = 'archivos/poblacion_la_pincoya/03_ni_comentarios_ni_opiniones/ficha_10_LP-03_Ni_comentarios_ni_opiniones_mi_cuerpo_mis_decisiones.pdf';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-ni-comentarios-ni-opiniones';
      break;
    case 'lp_lapincoya':
      tituloarchivo = 'La Pincoya';
      urlarchivo = 'archivos/poblacion_la_pincoya/04_poblacion_la_pincoya/ficha_11_LP-04_Poblacion_La_Pincoya.pdf';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-poblacion-la-pincoya';
      break;
    case 'lp_orgullosecundario':
      tituloarchivo = 'Orgullo Secundario';
      urlarchivo = 'archivos/poblacion_la_pincoya/05_orgullo_secundario/ficha_12_LP-05_Orgullo_Secundario.pdf';
      urlpaginaorigen = 'poblacion-la-pincoya/lp-orgullo-secundario';
      break;
    case 'sr_estacionneozona':
      tituloarchivo = 'Estación Neozona';
      urlarchivo = 'archivos/avenida_santa_rosa/01_estacion_neozona/ficha_14_SR-01_Estacion_Neozona.pdf';
      urlpaginaorigen = 'avenida-santa-rosa/sr-estacion-neozona';
      break;
    case 'sr_violetabestia':
      tituloarchivo = 'Violeta contra la bestia';
      urlarchivo = 'archivos/avenida_santa_rosa/02_violeta_contra_bestia/ficha_15_SR-02_Violeta_contra_la_bestia.pdf';
      urlpaginaorigen = 'avenida-santa-rosa/sr-violeta-contra-bestia';
      break;
    case 'rm_latemplanza':
      tituloarchivo = 'La Templanza';
      urlarchivo = 'archivos/rio_mapocho/01_la_templanza/ficha_16_RM-01_La_Templanza.pdf';
      urlpaginaorigen = 'rio-mapocho/rm-la-templanza';
      break;
    case 'rm_composicion':
      tituloarchivo = 'Sin título (composición de 4 obras)';
      urlarchivo = 'archivos/rio_mapocho/02_composicion_4_obras/Ficha_17_RM-02_Sin_titulo_(Composicion_de_4_Obras).pdf';
      urlpaginaorigen = 'rio-mapocho/rm-composicion-4-obras';
      break;
    case 'rm_mapochohistoria':
      tituloarchivo = 'El Mapocho vuelve a pintarse de historia';
      urlarchivo = 'archivos/rio_mapocho/03_mapocho_historia/ficha_18_RM-03_El_Mapocho_vuelve_a_pintarse_de_historia.pdf';
      urlpaginaorigen = 'rio-mapocho/rm-mapocho-historia';
      break;
    default: 
      urlarchivo = 'archivos/archivo_no_encontrado.pdf';
      break;
  }
  // FIN SELECCION ORIGEN PETICION
  
  const viewer = useRef(null);
  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        initialDoc: urlarchivo,
        disabledElements: ['leftPanelButton','panToolButton','selectToolButton','searchButton','viewControlsButton','viewControlsOverlay'],
        licenseKey: 'KzRLJ5OZ48FgBDKw86gu',
      },
      viewer.current,
    ).then((instance) => {
      const { Core, UI } = instance;
      instance.setLanguage('es');
      instance.UI.setTheme(instance.UI.Theme.DARK);
      Core.documentViewer.addEventListener('documentLoaded', () => {
        console.log('Documento Cargado');
      });
      Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
        console.log(`El número de página es: ${pageNumber}`);
      });
      UI.setHeaderItems(header => {
        header.push({
          type: 'actionButton',
          img: flecha_izquierda,
          onClick: () => {
            const currentPage = Core.documentViewer.getCurrentPage();
            const totalPages = Core.documentViewer.getPageCount();
            const atLastPage = currentPage === totalPages;
            if (atLastPage) {
              Core.documentViewer.setCurrentPage(1);
            } else {
              Core.documentViewer.setCurrentPage(currentPage - 1);
            }
          }
        });
        header.push({
          type: 'actionButton',
          img: flecha_derecha,
          onClick: () => {
            const currentPage = Core.documentViewer.getCurrentPage();
            const totalPages = Core.documentViewer.getPageCount();
            const atLastPage = currentPage === totalPages;
            if (atLastPage) {
              Core.documentViewer.setCurrentPage(1);
            } else {
              Core.documentViewer.setCurrentPage(currentPage + 1);
            }
          }
        });
      });
    });
  }, [urlarchivo]);

  return (
    <div class="container-fluid estilo_layout_contenido">
    <div class="estilo_div_visor_fichas">
      <div class="bd-highlight text-center mt-2">
      <h4 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">FICHA</h4>
        <h5 class="estilo_titulos_subtitulos_base estilo_verde_claro">{tituloarchivo}</h5>
      </div>
      <div class="webviewer border" ref={viewer}></div>
    </div>

    <div class="container-fluid fixed-bottom estilo_contenedor_breadcrumb_footer">
    <nav aria-label="breadcrumb" class="estilo_breadcrumb_footer">
      <ol class="breadcrumb pt-1 px-2 justify-content-center">
        <i class="bi bi-three-dots-vertical"></i>
          <li class="breadcrumb-item"><Link to={"/repositorio/"+urlpaginaorigen}>Volver</Link></li>
        <i class="bi bi-three-dots-vertical"></i>
      </ol>
    </nav>
    </div>
</div>
  );
};

export default VisorFichas;