import { Link } from 'react-router-dom';
import logoProyecto from '../imagenes/logo_conservacion_mural.png';
let datos = require('../data/datos_header.json');

function compHeader() {
  return (
    <div class="estilo_contenedor_header">
      <nav class="navbar navbar-dark bg-dark fixed-top estilo_fuente_menu estilo_barra_header">
        <div class="container-fluid">
          <Link class="navbar-brand" to="/"><img src={logoProyecto} alt="Logo Proyecto"/></Link>
           <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="offcanvas offcanvas-end text-bg-dark" style={{'width':'200px'}} tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div class="offcanvas-header">
              <h6 class="offcanvas-title" id="offcanvasDarkNavbarLabel"><strong>CONTENIDOS</strong></h6>
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                {datos.MenuHeader.map((item_menu) => (
                  <li class="nav-item" data-bs-dismiss="offcanvas">
                    <Link class="nav-link" to={"/"+item_menu.rutaitem}><i class={item_menu.iconoitem + " me-2"}></i>{item_menu.textoitem}</Link>
                  </li>
                ))} 
              </ul>
            </div>
          </div> 
        </div>
      </nav> 
    </div>
  )
}

export default compHeader;