import React from "react";
import BCInicio from '../componentes/BreadCrumbs/BCInicio';
import { Link } from 'react-router-dom';
let datos = require('../data/datos_actividades.json');

function Actividades() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(
    <div class="container-fluid py-3 lh-sm estilo_layout_contenido">
      <div class="text-center"><h1 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">ACTIVIDADES</h1></div>
      <div class="border rounded container-fluid pt-3 estilo_fondo_negro_semitransparente">
        {datos.menuActividades.map((menu_actividades) => (
          <div class="row justify-content-center pb-3 px-3">
            <div class="p-2 border rounded col-lg-4 col-md-4 col-sm-12 estilo_fondo_opciones">
              <Link to={"/actividades/"+menu_actividades.rutalink} class="text-decoration-none link-dark">
                <div class="d-flex align-items-center text-decoration-none">
                  <div>
                    <img class="border" src={require("../"+menu_actividades.rutaimagen)} alt={menu_actividades.altimagen}/>
                  </div>
                  <div class="ms-3 text-start"><strong>{menu_actividades.textolink}</strong></div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    <BCInicio />
    </div>
  )
}

export default Actividades;