import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Routes, Route} from "react-router-dom";

import Header from "./componentes/compHeader";
import Footer from "./componentes/compFooter";
import VisorFichas from "./modulos/VisorFichas";
import VisorLaminas from "./modulos/VisorLaminas";
import Error from "./modulos/Error";
import Inicio from "./modulos/Inicio";
import Contenidos from "./modulos/Contenidos";
import Proyecto from "./modulos/Proyecto";
import Equipo from "./modulos/Equipo";
import Repositorio from "./modulos/Repositorio";
import RepositorioFichas from "./modulos/RepositorioFichas";
import RepositorioDocumentos from "./modulos/RepositorioDocumentos";
import Actividades from "./modulos/Actividades";
import Multimedia from "./modulos/Multimedia";

import TallerMuralGraffiti from "./modulos/actividades/TallerMuralGraffiti";
import TallerMuralismoCienciaColor from "./modulos/actividades/TallerMuralismoCienciaColor";
import TallerPinturaMural from "./modulos/actividades/TallerPinturaMural";
import CharlaArteMuralUrbano from "./modulos/actividades/CharlaArteMuralUrbano";
import ConversatorioMuralesEspacioPubilico from "./modulos/actividades/ConversatorioMuralesEspacioPubilico";
import ConservacionMuralesEspacioPubilico from "./modulos/actividades/ConservacionMuralesEspacioPubilico";

import MuseoSanMiguel from "./modulos/repositorio/MuseoSanMiguel";
import PoblacionLaVictoria from "./modulos/repositorio/PoblacionLaVictoria";
import PoblacionLaPincoya from "./modulos/repositorio/PoblacionLaPincoya";
import AvenidaSantaRosa from "./modulos/repositorio/AvenidaSantaRosa";
import RioMapocho from "./modulos/repositorio/RioMapocho";

import SMPlazaUnion from "./modulos/repositorio/museo_san_miguel/SMPlazaUnion";
import SMLosPrisioneros from "./modulos/repositorio/museo_san_miguel/SMLosPrisioneros";
import SMCaminoIluminacion from "./modulos/repositorio/museo_san_miguel/SMCaminoIluminacion";
import SMHermanos from "./modulos/repositorio/museo_san_miguel/SMHermanos";

import LV31AnosVictoria from "./modulos/repositorio/poblacion_la_victoria/LV31AnosVictoria";
import LVDedicatoriaJarlanDubois from "./modulos/repositorio/poblacion_la_victoria/LVDedicatoriaJarlanDubois";
import LVParroquialJarlanDubois from "./modulos/repositorio/poblacion_la_victoria/LVParroquialJarlanDubois";

import LPCamiloCatrillanca from "./modulos/repositorio/poblacion_la_pincoya/LPCamiloCatrillanca";
import LPMujerPobladoraAllende from "./modulos/repositorio/poblacion_la_pincoya/LPMujerPobladoraAllende";
import LPNiComentariosNiOpiniones from "./modulos/repositorio/poblacion_la_pincoya/LPNiComentariosNiOpiniones";
import LPPoblacionLaPincoya from "./modulos/repositorio/poblacion_la_pincoya/LPPoblacionLaPincoya";
import LPOrgulloSecundario from "./modulos/repositorio/poblacion_la_pincoya/LPOrgulloSecundario";

import SREstacionNeozona from "./modulos/repositorio/avenida_santa_rosa/SREstacionNeozona";
import SRVioletaContraBestia from "./modulos/repositorio/avenida_santa_rosa/SRVioletaContraBestia";

import RMLaTemplanza from "./modulos/repositorio/rio_mapocho/RMLaTemplanza";
import RMComposicion4Obras from "./modulos/repositorio/rio_mapocho/RMComposicion4Obras";
import RMMapochoHistoria from "./modulos/repositorio/rio_mapocho/RMMapochoHistoria";

const routing = (
  <div>
      <HashRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Inicio />} />
          <Route path="/contenidos" element={<Contenidos />} />
          <Route path="/proyecto" element={<Proyecto />} />
          <Route path="/equipo" element={<Equipo />} />
          <Route path="/repositorio" element={<Repositorio />} />
          <Route path="/repositorio-fichas" element={<RepositorioFichas />} />
          <Route path="/repositorio-documentos" element={<RepositorioDocumentos />} />
          <Route path="/actividades" element={<Actividades />} />
          <Route path="/multimedia" element={<Multimedia />} /> 
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Error />}  />
          <Route path="/visorfichas/:idorigenpeticion" element={<VisorFichas />} />
          <Route path="/visorlaminas/:idorigenpeticion" element={<VisorLaminas />} />

          <Route path="/actividades/taller-mural-graffiti" element={<TallerMuralGraffiti />} />
          <Route path="/actividades/taller-muralismo-ciencia-color" element={<TallerMuralismoCienciaColor />} />
          <Route path="/actividades/taller-pintura-mural" element={<TallerPinturaMural />} />
          <Route path="/actividades/charla-arte-mural-urbano" element={<CharlaArteMuralUrbano />} />
          <Route path="/actividades/conversatorio-murales-espacio-publico" element={<ConversatorioMuralesEspacioPubilico />} />
          <Route path="/actividades/conservacion-murales-espacio-publico" element={<ConservacionMuralesEspacioPubilico />} />
          
          <Route path="/repositorio/museo-san-miguel" element={<MuseoSanMiguel />} />
          <Route path="/repositorio/poblacion-la-victoria" element={<PoblacionLaVictoria />} />
          <Route path="/repositorio/poblacion-la-pincoya" element={<PoblacionLaPincoya />} />
          <Route path="/repositorio/avenida-santa-rosa" element={<AvenidaSantaRosa />} />
          <Route path="/repositorio/rio-mapocho" element={<RioMapocho />} />

          <Route path="/repositorio/museo-san-miguel/sm-plaza-union" element={<SMPlazaUnion />} />
          <Route path="/repositorio/museo-san-miguel/sm-los-prisioneros" element={<SMLosPrisioneros />} />
          <Route path="/repositorio/museo-san-miguel/sm-camino-iluminacion" element={<SMCaminoIluminacion />} />
          <Route path="/repositorio/museo-san-miguel/sm-hermanos" element={<SMHermanos />} />

          <Route path="/repositorio/poblacion-la-victoria/lv-31-anos-victoria" element={<LV31AnosVictoria />} />
          <Route path="/repositorio/poblacion-la-victoria/lv-dedicatoria-jarlan-dubois" element={<LVDedicatoriaJarlanDubois />} />
          <Route path="/repositorio/poblacion-la-victoria/lv-parroquial-jarlan-dubois" element={<LVParroquialJarlanDubois />} /> 

          <Route path="/repositorio/poblacion-la-pincoya/lp-camilo-catrillanca" element={<LPCamiloCatrillanca />} />
          <Route path="/repositorio/poblacion-la-pincoya/lp-mujer-pobladora-allende" element={<LPMujerPobladoraAllende />} />
          <Route path="/repositorio/poblacion-la-pincoya/lp-ni-comentarios-ni-opiniones" element={<LPNiComentariosNiOpiniones />} />
          <Route path="/repositorio/poblacion-la-pincoya/lp-poblacion-la-pincoya" element={<LPPoblacionLaPincoya />} />
          <Route path="/repositorio/poblacion-la-pincoya/lp-orgullo-secundario" element={<LPOrgulloSecundario />} />

          <Route path="/repositorio/avenida-santa-rosa/sr-estacion-neozona" element={<SREstacionNeozona />} />
          <Route path="/repositorio/avenida-santa-rosa/sr-violeta-contra-bestia" element={<SRVioletaContraBestia />} />

          <Route path="/repositorio/rio-mapocho/rm-la-templanza" element={<RMLaTemplanza />} />
          <Route path="/repositorio/rio-mapocho/rm-composicion-4-obras" element={<RMComposicion4Obras />} />
          <Route path="/repositorio/rio-mapocho/rm-mapocho-historia" element={<RMMapochoHistoria />} />

        </Routes>
        <Footer />
      </HashRouter>
    
  </div>
);

ReactDOM.render(routing, document.getElementById("root"));