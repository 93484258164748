import React from "react";
import BCInicio from '../componentes/BreadCrumbs/BCInicio';
let datos = require('../data/datos_multimedia.json');

function Multimedia() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return(
		<div class="container-fluid py-3 lh-sm estilo_layout_contenido">
			<div class="text-center">
				<h1 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">MULTIMEDIA</h1>				
			</div>
			<div class="border rounded container-fluid pt-3 estilo_fondo_negro_semitransparente">
				<div class="row row-cols-1 row-cols-md-3 g-4">
					{datos.Videos.map((items_videos) => ( 
					<React.Fragment>
						{/* CARD */}
						<div class="col card-group">
							<div class="card border border-white bg-transparent mb-3">
								<div class="d-flex card-header estilo_cabeceras_equipo">
									<div class="col-12 text-start estilo_texto_nombre text-dark"><h6 class="fw-bold">{items_videos.nombreObra}</h6></div>
								</div>
								<div class="p-2 border mt-3 mx-3">
 									<div class="text-center div_contenedor_video_multimedia">
										<iframe width="100%" height="100%" src={items_videos.urlVideo} title="YouTube Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" class="video_multimedia" allowfullscreen=""></iframe>
									</div>
								</div>
								<div class="card-body">
									<p class="card-text mb-0"><small>{items_videos.descripcionVideo}</small></p>
								</div>
							</div>
						</div>
					</React.Fragment>
					))}
				</div>
			</div>
			<BCInicio />
		</div>
	)
}

export default Multimedia;