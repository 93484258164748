import React from "react";
import { Link } from 'react-router-dom';
import BCRepositorioFichas from '../../componentes/BreadCrumbs/BCRepositorioFichas';
let datos = require('../../data/repositorio/datos_rio_mapocho.json');

function RioMapocho() {
	React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(    
    <div class="container-fluid py-3 lh-sm estilo_proyecto">
      <div class="text-center"><h1 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">Fichas de Caracterización y Diagnóstico</h1></div>
      <div class="bd-highlight text-center">
        <h3 class="estilo_titulos_subtitulos_base estilo_verde_claro">{datos.tituloMenu}</h3>
      </div>
			<div class="border rounded container-fluid pt-3 estilo_fondo_negro_semitransparente">
				{datos.menuRioMapocho.map((menu_item_repositorio) => (
					<div class="row justify-content-center pb-3 px-3">
						<div class="p-2 border rounded col-lg-4 col-md-4 col-sm-12 estilo_fondo_opciones">
							<Link to={"/repositorio/"+menu_item_repositorio.rutalink} class="text-decoration-none link-dark">
								<div class="d-flex align-items-center">
									<div><img class="border" src={require("../../imagenes/repositorio/"+menu_item_repositorio.rutaimagen)} alt={menu_item_repositorio.altimagen}/></div>
									<div class="ms-2 text-start"><strong>{menu_item_repositorio.textolink}</strong></div>
								</div>
							</Link>
						</div>
					</div>
				))}
			</div>
			<BCRepositorioFichas />
    </div>
  )
}

export default RioMapocho;