import React from "react";
import BCActividades from '../../componentes/BreadCrumbs/BCActividades'
let datos = require('../../data/actividades/datos_charla_arte_mural_urbano.json');
const imagenclass = 'col-lg-3 col-md-4 col-sm-12 p-2';

function CharlaArteMuralUrbano() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(    
    <div class="container-fluid py-3 lh-sm estilo_layout_contenido">        
      <div class="text-center"><h1 class="estilo_titulos_subtitulos_base estilo_verde_oscuro">ACTIVIDADES</h1></div>
      <div class="bd-highlight text-center">
        <h3 class="estilo_titulos_subtitulos_base estilo_verde_claro">{datos.nombreTaller}</h3>
      </div>
      <div class="border rounded container-fluid py-1 estilo_fondo_negro_semitransparente">
        <p><small><strong>Fecha: </strong>{datos.fechaRealizacion}</small></p>
        <p><small><strong>{datos.funcionEjecutor + ':'}</strong><br/>- {datos.ejecutorUno}</small></p>
        <hr class="border-2" />
        {datos.Descripcion.map((parrafo_descripcion) => (
          <p><small>{parrafo_descripcion}</small></p>
        ))}
        <div class="row">
          {datos.ImagenesActividad.map((img_actividad) => (
            <div class={imagenclass}><div class="card"><div class="card-body estilo-card-img-gallery"><img data-bs-toggle="modal" data-bs-target={"#"+img_actividad.nombremodal} class="card-img" src={require("../../"+img_actividad.rutaimagen)} alt={img_actividad.altimagen}/></div></div></div>
          ))}
        </div>
      </div>
      {datos.ImagenesActividad.map((modal_actividad) => (
        <div class="modal fade" id={modal_actividad.nombremodal} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header estilo_cabecera_modal_fotos">
                <h1 class="modal-title fs-6 estilo_texto_cabecera_modal_fotos" id="exampleModalLabel"><small>{modal_actividad.tituloimagen}</small></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body"><img class="card-img" src={require("../../"+modal_actividad.rutaimagen)} alt={modal_actividad.altimagen}/></div>
            </div>
          </div>
        </div>
      ))}
    <BCActividades />
    </div>
  )
}

export default CharlaArteMuralUrbano;