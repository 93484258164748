function BCPortada() {
	return(
		<div class="container-fluid fixed-bottom estilo_contenedor_breadcrumb_footer">
			<nav aria-label="breadcrumb" class="estilo_breadcrumb_footer">
				<ol class="breadcrumb pt-1 px-2 justify-content-center"></ol>
			</nav>
		</div>
	)
}

export default BCPortada;