import React from "react";
import BCPortada from '../componentes/BreadCrumbs/BCPortada';
let datos = require('../data/datos_inicio.json');

function Inicio2() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(
    <div className="container-fluid">
      <div style={{paddingTop: '100px'}}>
      <h1 class="estilo_titulos_subtitulos_base estilo_verde_oscuro text-center">{datos.nombreProyecto_L1}</h1>
      <h2 class="estilo_titulos_subtitulos_base estilo_verde_claro text-center">{datos.nombreProyecto_L2}</h2>
      </div>
      <BCPortada />
    </div>
  )
}

export default Inicio2;