import logoMinisterioFooter from '../imagenes/img_logo_ministerio_footer_b.png';
import textoConvocatoriaFooter from '../imagenes/img_texto_convocatoria_footer_b.png';
import textoProyectoFooter from '../imagenes/img_texto_proyecto_footer_b.png';

const compFooter = () => {
  return (
    <div class="estilo_contenedor_footer">
      <nav class="navbar fixed-bottom justify-content-center estilo_barra_footer">
          <footer>
            <div class="row">
              <div class="text-center ">
                <a href="https://www.cultura.gob.cl/" target="_blank" rel="noreferrer" class="text-decoration-none"><img src={logoMinisterioFooter} alt="Logo Ministerio Cultura"/></a>
                <img src={textoConvocatoriaFooter} alt="Texto Convocatoria"/>
              </div>
            </div>
            <div class="row">
              <div class="text-center ">
                <img src={textoProyectoFooter} alt="Texto Proyecto"/>
              </div>
            </div>
          </footer>
      </nav>
    </div>
  )
}

export default compFooter;